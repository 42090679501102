@font-face {
  font-family: OpenSans;
  src: url(../assets/Open_Sans/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: OpenSans;
  src: url(../assets/Open_Sans/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
}
