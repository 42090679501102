@font-face {
  font-family: OpenSans;
  src: url("OpenSans-Regular.14897fed.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: OpenSans;
  src: url("OpenSans-Bold.b1e739a0.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("Roboto-Regular.77718ea6.ttf") format("truetype");
  font-weight: normal;
}

* {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: OpenSans, Roboto;
}

body {
  color: #fff;
  background: #0b1828;
}

main {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  display: flex;
}

img {
  width: 300px;
  height: auto;
}

h1, h3 {
  text-transform: uppercase;
  color: #fff;
  display: block;
}

h1 {
  font-size: 42px;
}
/*# sourceMappingURL=index.1ad58dc0.css.map */
