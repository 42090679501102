@import "fonts.css";

* {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: OpenSans, Roboto;
}

body {
  background: #0b1828;
  color: white;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}

img {
  width: 300px;
  height: auto;
}

h1,
h3 {
  display: block;
  text-transform: uppercase;
  color: white;
}

h1 {
  font-size: 42px;
}
